// Função para tocar o áudio repetidas vezes
export const playAudioRepeatedly = (audioElement: HTMLAudioElement, times: number) => {
  let playCount = 0;

  const playAudio = () => {
    if (playCount < times && audioElement) {
      stopAudio(audioElement);
      audioElement.play().then(() => {
        playCount++;
        if (playCount < times) {
          setTimeout(playAudio, 1300); // Espera 1.3 segundos entre as execuções
        }
      });
    }
  };

  playAudio();
};

// Função para parar o áudio
export const stopAudio = (audioElement: HTMLAudioElement) => {
  if (audioElement) {
    audioElement.pause();
    audioElement.currentTime = 0; // Reseta o tempo do áudio
  }
};