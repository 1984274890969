import React, { useEffect, useState } from "react";
import { Balcao } from "../../services/BalcaoVirtualService";
import iconAtendenteCirculado from "../../assets/images/icon/icon-atendente-circulado.svg";
import "./style.css";
import consumer from "../../config/ws";

interface Props {
  show: boolean;
}

const channelInfo = {
  command: 'subscribe',
  identifier: JSON.stringify({
    channel: 'GuicheChannel'
  }),
};

export default function ListBalcaoVirtual({ show }: React.PropsWithChildren<Props>) {
  const [loading, setLoading] = useState(true);
  const [balcoes, setBalcoes] = useState<Balcao[]>([]);

  useEffect(() => {
    consumer.onopen = handleOnOpen;
    consumer.onmessage = handleOnMessage;

    setLoading(false);
  }, []);

  const handleOnOpen = () => {
    consumer.send(JSON.stringify(channelInfo));
  }

  const handleOnMessage = (event: any) => {
    const response = event.data;
    const msg = JSON.parse(response);

    // Ignores pings.
    if (msg.type === "ping") return;

    // Renders any newly created messages onto the page.
    if (msg.message) {
      let { guiches } = msg.message;

      setBalcoes(guiches);
    }
  }

  if (!show) {
    return null;
  }

  const btnCardBalcao = (className: string, link: string, btnText: string) => {
    return (
      <a
        className={`btn mt-4 p-3 btn-list-balcoes ${className}`}
        href={link} target="_blank">
        {btnText}
      </a>
    )
  }

  const verificarStatusBalcao = (status: string) => {
    switch (status) {
      case "available":
        return "Atendente disponível para chamada. =)";
      case "busy":
        return "Atendente já está em chamada. Aguarde disponibilidade.";
      default:
        return "Atendente não disponível no momento.";
    }
  };

  const renderBtn = (status: string, link: string) => {
    switch (status) {
      case "available":
        return (btnCardBalcao("available", link, "Iniciar atendimento"));
      case "busy":
        return (btnCardBalcao("disable", "#", "Ocupado"));
      default:
        return (btnCardBalcao("disable-unavailable", "#", "Indisponível"));
    }
  };

  const msg_loadding = () => {
    if (balcoes.length === 0 && loading) {
      return (<div className="loading-lista-balcoes alert-warning alert">Estamos carregando os guichês de atendimento, aguarde por favor!</div>)
    } else {
      return (<></>)
    }
  }

  return (
    <div id="" className="list-balcao-virtual  pt-2">
      {msg_loadding()}

      <div className="container-fluid pt-2">
        <div className="row">
          {balcoes?.map((guiche, i) => {
            return (
              <div key={i} className="col-xs-12 col-md-4 col-lg-3 col-sm-4 d-flex mt-4" style={{ justifyContent: 'space-between' }}>
                <div className="card text-center pt-4 pb-4">
                  <div className="container">
                    <img className="card-img-top icon-top-card" src={iconAtendenteCirculado} />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title "><strong>{guiche.name}</strong></h5>
                    <div className="card-balcao-info">
                      <p className="card-text">
                        {verificarStatusBalcao(guiche.status)}
                      </p>
                      <p className="card-text m-0">
                        {/* {guiche.id <= 5 ? " Prioritário para pagamento à vista" : ""} */}
                      </p>
                    </div>
                    {renderBtn(guiche.status, guiche.meet_link)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
